import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Gist from 'react-gist';
import '../assets/css/style.css';

const IndexPage = () => (
  <Layout>
    <SEO title="Email Design Service For Amazon Web Service SES" />
   
  </Layout>
)

export default IndexPage
